<template>
  <span class="brand-logo">
    <b-img
      :src="base64 != null ? base64 : require(`@/assets/images/logo/Novos/logoCliente.png`)"
      alt="logo"
      variant="light-primary"
      badge
      class="badge-minimal"
      badge-variant="success"
      height="80"
      ref="refPreviewEl"
      rounded
    />
  </span>
</template>
<script>
  export default {
    props: {
      base64: {
        type: String,
        default: null,
      },
    },
  }
</script>
