<template>
  <div class="wizard" :class="{ 'wizard-step-1': currentStep === 1, 'wizard-step-2': currentStep === 2 }">
    <div class="progress-line"></div>
    <div class="static-line"></div>
    <div class="step">
      <span class="step-ball"></span>
      <span class="step-title">Suas Informações</span>
    </div>
    <div class="step simulation-step">
      <span class="simulation-step-ball"></span>
      <span :class="{ 'step-title': currentStep === 1, 'step-title-2': currentStep === 2 }">Simular Valores</span>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      currentStep: {
        type: Number,
        default: 1,
      },
    },
  }
</script>

<style lang="scss">
  // Importações de arquivos de estilo
  @import '@core/scss/vue/pages/page-auth.scss';
  @import '@core/scss/vue/libs/vue-select.scss';

  // Estilos gerais do assistente (wizard)
  .wizard {
    margin-left: 15px;
    position: relative;

    // Linhas de progresso e linha estática
    .progress-line,
    .static-line {
      width: 2px;
      position: absolute;
      margin-left: -15px;
    }

    .static-line {
      background-color: #ccc;
      height: 410px;
    }

    // Estilos comuns para os passos
    .step,
    .simulation-step {
      padding-top: 59px;
      padding-bottom: 164px;
      position: relative;
    }

    .simulation-step {
      padding-bottom: unset;
    }

    // Estilos para as bolas dos passos
    .step-ball,
    .simulation-step-ball {
      content: '';
      display: block;
      width: 18px;
      height: 18px;
      border-radius: 20px;
      position: absolute;
      margin-left: -23px;
      background-color: var(--primary);
      top: 60px; // Ajuste conforme necessário
    }

    // Estilos para os títulos dos passos
    .step-title,
    .step-title-2 {
      font-weight: bold;
    }
  }

  // Estilos específicos para o passo 1 do assistente
  .wizard-step-1 {
    .progress-line {
      background-color: var(--primary);
      height: 60px;
      z-index: 1;
    }

    .simulation-step::before {
      background-color: #ccc;
      top: 157px;
    }
    .simulation-step-ball {
      background-color: #ccc;
    }
  }

  // Estilos específicos para o passo 2 do assistente
  .wizard-step-2 {
    .progress-line {
      background-color: var(--primary);
      height: 338px;
      z-index: 1;
    }

    .simulation-step::before {
      background-color: var(--primary);
      top: 157px;
    }
  }
</style>
